import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Wrapper for clsx and tailwind-merge to make it easier to use.
 *
 * E.G.
 *
 * Kitchen sink (with nesting)
 *
 * clsx('foo', [1 && 'bar', { baz:false, bat:null }, ['hello', ['world']]], 'cya');
 *
 * => 'foo bar hello world cya'
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function markupFormatting(body: string) {
  return body.replaceAll("<p", '<p class="my-1.5"');
}

export function getRedirectFromRequest(request: Request) {
  const url = new URL(request.url);
  const redirectString = url.searchParams.get("redirect");
  if (redirectString) {
    return redirectString;
  }
  return undefined;
}

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

// Helper function to prevent jerky UX
export const smooth = async <T>(func: Promise<T>, ms: number): Promise<T> => {
  const [res] = await Promise.all([func, sleep(ms)]);
  return res;
};

export const ucFirst = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
